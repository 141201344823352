<template>
  <div v-if="filter" class="logs-view">
    <tirscript3-loading :loading="isLoading"></tirscript3-loading>
    <tirscript3-table
      :items="items"
      template-columns="auto auto auto 190px 300px auto auto"
      style="overflow-x: auto"
    >
      <template #header>
        <tirscript3-table-header-item>Дата</tirscript3-table-header-item>
        <tirscript3-table-header-item>Уровень</tirscript3-table-header-item>
        <tirscript3-table-header-item>Метод</tirscript3-table-header-item>
        <!-- <tirscript3-table-header-item>URL</tirscript3-table-header-item> -->
        <tirscript3-table-header-item>
          Размеры и время Request / Response/Time
        </tirscript3-table-header-item>
        <tirscript3-table-header-item>
          SessionToken RequestId ConnectionId
        </tirscript3-table-header-item>
        <tirscript3-table-header-item>UserId Ip</tirscript3-table-header-item>
        <tirscript3-table-header-item>Действия</tirscript3-table-header-item>
      </template>
      <template #body="data">
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ $filters.DateFormat(data.item.DateStart) }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ $filters.LogLevel(data.item.LevelId) }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ data.item.Class }} / {{ data.item.Method }}
        </tirscript3-table-body-item>
        <!-- <tirscript3-table-body-item>
          {{ data.item.Url }}
        </tirscript3-table-body-item> -->
        <tirscript3-table-body-item class="table-item" :class="[type(data.item)]">
          {{ data.item.RequestLength }}байт / {{ data.item.ResponseLength }}байт
          / {{ getIntervalTime(data.item.DateStart, data.item.DateEnd) }}ms
        </tirscript3-table-body-item>
        <tirscript3-table-body-item class="session-token table-item" :class="[type(data.item)]">
          {{ data.item.SessionToken }} <br />
          {{ data.item.RequestId }} <br />{{
            data.item.ConnectionId
          }}</tirscript3-table-body-item
        >
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          {{ data.item.UserId }} <br />
          {{ data.item.Ip }}
        </tirscript3-table-body-item>
        <tirscript3-table-body-item  class="table-item" :class="[type(data.item)]">
          <router-link
            :to="{
              name: 'logs-view-detail',
              params: { id: data.item.RequestId },
            }"
            class="detale"
          >
            Просмотр
          </router-link>
        </tirscript3-table-body-item>
      </template>
    </tirscript3-table>
    <div v-show="!noPagination && items && items.length > 0" class="panel">
      <div class="row align-items-center">
        <div class="col-auto ml-auto">
          <tirscript3-pagination
            ref="pagination"
            :size="pageResponse.Size"
            :count="pageResponse.Count"
            class="mt-4"
            @onChange="onPaginationChanged($event)"
          >
          </tirscript3-pagination>
          <!-- <tirscript3-pagination class="mt-4" :count="page.Count" :size="page.Size" ref="pagination" @onChange="onPaginationChanged($event)"></tirscript3-pagination> -->
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="!noPagination && items && items.length == 0 && isLoading == false"
    >
      <div class="pb-2 pt-4 col-12" style="text-align: center">Нет данных</div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import FilterModel from "./components/filter-model";
import GetEndpointLogsRequest from "@dataSource/LoggerMicroService/Services/Models/Request/LogGetterService/GetEndpointLogsRequest";
import EndpointLogsItemResponse from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse";
import GetEndpointLogsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogsResponse";
import getUTC from "@/function/getUTC"
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "LogsViewPage",
  components: {
    TableView,
  },
})
export default class LogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  innerFilter: GetEndpointLogsRequest;
  items: EndpointLogsItemResponse[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    if (this.$route.name == "content-logs-view") {
      this.refresh();
    }
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }
    //console.log('refresh',this.filter);

    let response: GetEndpointLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      OnlyActiveRequests: this.filter.OnlyActiveRequests,
      EndpointId: this.filter.EndpointId,
      LevelId: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId:
        this.filter.ProjectLogGroupId === -1
          ? null
          : this.filter.ProjectLogGroupId,
      Page: { Skip: this.skipPage, Take: this.takePage },
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
    };

    let tmpFilter = getUTC(this.innerFilter)
    try {
      response = await this.$api.LogGetterService.getEndpointLogsAsync(
        tmpFilter
      );
      // this.page.Count = response.Page.Count
      // console.log(this.page.Count, 'this.page.Count');
    } catch (error) {
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;
    this.pageResponse = response.Page;


    this.items = response.Items.map((item) => ({
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      EndpointId: item.EndpointId,
      Class: item.Class,
      Method: item.Method,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
    }));
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;

    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
}
</script>

<style lang="less">
.logs-view {
  .detale {
    color: blue;
    cursor: pointer;
  }
}

.logs-view .tirscript3-table .tirscript3-table-header-item,
.logs-view .tirscript3-table .tirscript3-table-body-item {
  justify-content: center;
  text-align: center;
}

.session-token {
  font-family: monospace;
  font-size: 13px !important;
}
</style>
<style scoped>
.table-item.error {
  background: #FED6BC;
}
.table-item.warn {
  background: #FDEED9;
}
</style>