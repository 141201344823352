
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import PageResponse from "@/dataSource/api/PaginationHelper/Lib/PageResponse";
import FilterModel from "./components/filter-model";
import GetEndpointLogsRequest from "@dataSource/LoggerMicroService/Services/Models/Request/LogGetterService/GetEndpointLogsRequest";
import EndpointLogsItemResponse from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/EndpointLogsItemResponse";
import GetEndpointLogsResponse from "@dataSource/LoggerMicroService/Services/Models/Response/LogGetterService/GetEndpointLogsResponse";
import getUTC from "@/function/getUTC"
import EnumLogLevel from '@dataSource/Tirscript/LoggerMicroService/Enums/EnumLogLevel'

@Options({
  name: "LogsViewPage",
  components: {
    TableView,
  },
})
export default class LogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  innerFilter: GetEndpointLogsRequest;
  items: EndpointLogsItemResponse[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return 'error'
      case EnumLogLevel.Warn:
        return 'warn'
      default:
        return 'default'
    }
  }

  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    if (this.$route.name == "content-logs-view") {
      this.refresh();
    }
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }
    //console.log('refresh',this.filter);

    let response: GetEndpointLogsResponse = null;

    this.isLoading = true;
    // this.innerFilter.ToDate = (this.filter as any).DateTo;
    // this.innerFilter.FromDate = (this.filter as any).DateFrom;

    this.innerFilter = {
      OnlyActiveRequests: this.filter.OnlyActiveRequests,
      EndpointId: this.filter.EndpointId,
      LevelId: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Ip: this.filter.Ip,
      UserId: this.filter.UserId,
      SessionToken: this.filter.SessionToken,
      ProjectLogGroupId:
        this.filter.ProjectLogGroupId === -1
          ? null
          : this.filter.ProjectLogGroupId,
      Page: { Skip: this.skipPage, Take: this.takePage },
      ConnectionId: this.filter.ConnectionId,
      RequestId: this.filter.RequestId,
    };

    let tmpFilter = getUTC(this.innerFilter)
    try {
      response = await this.$api.LogGetterService.getEndpointLogsAsync(
        tmpFilter
      );
      // this.page.Count = response.Page.Count
      // console.log(this.page.Count, 'this.page.Count');
    } catch (error) {
      console.log(`error:`, error);
      this.isLoading = false;
    }
    this.isLoading = false;
    this.pageResponse = response.Page;


    this.items = response.Items.map((item) => ({
      DateStart: item.DateStart,
      DateEnd: item.DateEnd,
      EndpointId: item.EndpointId,
      Class: item.Class,
      Method: item.Method,
      RequestId: item.RequestId,
      RequestLength: item.RequestLength,
      Ip: item.Ip,
      LevelId: item.LevelId,
      SessionToken: item.SessionToken,
      ConnectionId: item.ConnectionId,
      ResponseLength: item.ResponseLength,
      UserId: item.UserId,
    }));
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;

    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
}
