<template>
  <page name="Просмотр активных методов" :selectSource="false">
    <template v-slot="slotProps">
      <filter-component
        v-model:filter="filterModel"
        @filterData="filterData"
        :selectInterval="false"
        :methods="true"
        :logsType="false"
      ></filter-component>
      <!-- <logs-view-filter 
        v-model:filter="filterModel" 
        :logsType="false"
        :logsLevel="false"
        :methods="true"
        :url="false"
        :requestId="true">
        </logs-view-filter> -->
      <!-- <view-filter
        v-model:filter="filterModelView"
        :logsType="false"
        :methods="true"
      /> -->

      <logs-view :filter="filterModelView"></logs-view>
      <!-- <active-logs-view :filter="filterModelView"></active-logs-view> -->

      <bs-row v-if="!filterModelView">
        <bs-col>
          <div class="set-filter">Нажмите кнопку отфильтровать!</div>
        </bs-col>
      </bs-row>
    </template>
  </page>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import Page from "@views/page/components/page.vue";
import LogsView from "@views/page/logs-view.vue";

import ViewFilter from "@views/page/view-filter.vue";
//import LogsViewFilter from "@views/page/logs-view-filter.vue";
import PageRequest from "@dataSource/PaginationHelper/Lib/PageRequest";
import FilterModel from "../components/filter-model";
import getUtc from "@/filters/getUtc";

@Options<ActiveMethodsLogsViewPage>({
  components: {
    Page,
    //LogsViewFilter,
    ViewFilter,
    LogsView,
  },
  props: { slotProps: {} },

  watch: {
    //параметры заходящие через слот
    //пока только идентификатор источника логов(sourceLogId)
    slotProps: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value?.sourceLogId) {
          this.filterModel.source = value.sourceLogId;
        }
      },
    },
    filterModel: {
      deep: true,
      handler(value) {
        // this.saveInLocalStor();
      },
    },
  },
})
export default class ActiveMethodsLogsViewPage extends Vue {
  readonly slotProps = {};
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();
  filterModelView: FilterModel = null;
  getUtc = getUtc;
  mounted() {
    this.getInLocalStor();
    // подписываемся на событие отфильтровать в компоненте Page
    document.addEventListener(
      "filterChange",
      this.onclickFilterChange.bind(this)
    );
    //чтобы скопировать значение, а ссылку  и исключить реактивность. Обновление только по кнопке отфильтровать.
    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
  }
  destroy() {
    document.removeEventListener(
      "filterChange",
      this.onclickFilterChange.bind(this)
    );
  }
  filterData() {
    this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
  }
  onclickFilterChange() {
    console.log("onclickFilterChange", this.filterModel);

    // ловим событие нажатия кнопки отфильтровать
    this.filterModel.DateTo = this.filterModel.DateTo;
    this.filterModel.DateFrom = this.filterModel.DateFrom;

    // this.filterModelView = JSON.parse(JSON.stringify(this.filterModel));
    this.filterModelView.OnlyActiveRequests = true;
    // this.filterModelView.LogLevel = undefined;
    //сохраняем в локал сторедж состояние фильтра для текущей страницы
    this.saveInLocalStor();
  }

  //сохранить модель фильтра для страницы
  saveInLocalStor() {
    localStorage.activeMethodLogsViewPage = JSON.stringify(
      this.filterModelView
    );
  }
  //получить данные фильтра для страницы если они есть
  getInLocalStor() {
    if (localStorage.activeMethodLogsViewPage) {
      this.filterModel = JSON.parse(localStorage.activeMethodLogsViewPage);
      const t = new Date().getTimezoneOffset();
      this.filterModel.DateFrom = this.addMinutes(this.filterModel.DateFrom, t);
      this.filterModel.DateTo = this.addMinutes(this.filterModel.DateTo, t);
      // this.filterModel.DateTo = new Date(this.filterModel.DateTo);
    }
  }

  addMinutes(date, minutes) {
    return new Date(new Date(date).getTime() + minutes * 60000);
  }
  //var date = convertUTCDateToLocalDate(new Date(date_string_you_received));
}
</script>

<style lang="less">
.other-logs-view-page {
  .min-width {
    min-width: 150px;
  }
}
</style>